@use "sass:map";

@import "./../required";

.card-collection-avatar {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    color: $gray-800;

    > div {
        line-height: normal;
    }

    strong {
        display: block;
    }

    .avatar {
        margin-right: $spacer;
    }

    .alias {
        font-weight: $font-weight-bold;
        color: $primary;
    }
}

.card-collection-header {
    display: flex;
    justify-content: space-between;
    padding: $spacer;
    border-bottom: $border-width solid $gray-200;

    > *:last-child:not(.card-collection-avatar) {
        margin-left: auto;
    }
}

.card-collection-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-200;
    border-bottom: $border-width solid $gray-200;
}

.card-collection-details {
    padding: $spacer;
}

.card-collection-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map.get($spacers, 2) $spacer;
    background-color: $gray-200;

    > .btn,
    > div {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
    }

    .btn-light {
        background-color: transparent;
        border-color: transparent;
    }
}

.card-collection {
    overflow: hidden;
    cursor: pointer;
    background-color: $white;
    border-radius: $border-radius;

    .card-title {
        font-weight: 700;
        color: $gray-800;
        word-break: break-all;
    }

    .card-subtitle {
        color: $gray-600;
    }
}
